import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useMediaQuery from "../hooks/useMediaQuery"
import Layout from "../components/Layout"

import {
  grid,
  column,
  item,
  itemImage,
  itemContent,
  gridMobile,
} from "../styles/grid.module.css"

export default function ArtDesign({ data }) {
  const isMobile = useMediaQuery("(max-width: 900px)")

  const projects = data.allContentfulProject.edges.map(edge => edge.node)

  const [column1, setColumn1] = useState([])
  const [column2, setColumn2] = useState([])
  const [column3, setColumn3] = useState([])

  useEffect(() => {
    let counter = 0
    projects.forEach(project => {
      if (counter >= 3) counter = 0
      let i = counter + 3

      if (i % 3 === 0) {
        setColumn1(column1Projects => [...column1Projects, project])
      } else if (i % 2 === 0) {
        setColumn2(column2Projects => [...column2Projects, project])
      } else {
        setColumn3(column3Projects => [...column3Projects, project])
      }
      counter += 1
    })
  }, [])

  function getImageTag(project) {
    // TODO: gifs?
    if (project && project.gifPath) {
      return (
        <img className={itemImage} src={project.gifPath} alt={project.title} />
      )
    }
    return (
      <GatsbyImage
        className={itemImage}
        image={getImage(project.thumbnail)}
        alt={project.title}
      />
    )
  }

  function getDesktop() {
    return (
      <section className={`${grid}`}>
        <section className={column}>
          {column1.map(project => {
            return (
              <Link
                to={`/art-design/${project.slug}`}
                key={project.id}
                className={item}
              >
                {getImageTag(project)}
                <div className={itemContent}>
                  <h2>{project.title}</h2>
                  <p>{project.linkText}</p>
                </div>
              </Link>
            )
          })}
        </section>
        <section className={column}>
          {column2.map(project => {
            return (
              <Link
                to={`/art-design/${project.slug}`}
                key={project.id}
                className={item}
              >
                {getImageTag(project)}
                <div className={itemContent}>
                  <h2>{project.title}</h2>
                  <p>{project.linkText}</p>
                </div>
              </Link>
            )
          })}
        </section>
        <section className={column}>
          {column3.map(project => {
            return (
              <Link
                to={`/art-design/${project.slug}`}
                key={project.id}
                className={item}
              >
                {getImageTag(project)}
                <div className={itemContent}>
                  <h2>{project.title}</h2>
                  <p>{project.linkText}</p>
                </div>
              </Link>
            )
          })}
        </section>
      </section>
    )
  }

  function getMobile() {
    return (
      <section className={`${grid} ${gridMobile}`}>
        <section className={column}>
          {projects.map(project => {
            return (
              <Link
                to={`/art-design/${project.slug}`}
                key={project.id}
                className={item}
              >
                {getImageTag(project)}
                <div className={itemContent}>
                  <h2>{project.title}</h2>
                  <p>{project.linkText}</p>
                </div>
              </Link>
            )
          })}
        </section>
      </section>
    )
  }

  return <Layout>{isMobile ? getMobile() : getDesktop()}</Layout>
}

// Export page query
export const query = graphql`
  query ArtDesignPage {
    allContentfulProject(
      filter: { context: { eq: "art-design" } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          context
          date
          slug
          thumbnail {
            gatsbyImageData
          }
          linkText
          title
        }
      }
    }
  }
`
